import * as React from "react";
import { styled } from "@mui/material/styles";
import Badge from "@mui/material/Badge";
import Avatar from "@mui/material/Avatar";
import Stack from "@mui/material/Stack";
import Popover from "@mui/material/Popover";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import { useAppStore } from "../../appStore";
import { deepOrange } from "@mui/material/colors";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  ListItemIcon,
  Typography,
} from "@mui/material";
import LogoutIcon from "@mui/icons-material/Logout";
import ReportIcon from "@mui/icons-material/Report";
import { useState } from "react";
import { api_url } from "../../apiUtils";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";

const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    backgroundColor: "#44b700",
    color: "#44b700",
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    "&::after": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      animation: "ripple 1.2s infinite ease-in-out",
      border: "1px solid currentColor",
      content: '""',
    },
  },
  "@keyframes ripple": {
    "0%": {
      transform: "scale(.8)",
      opacity: 1,
    },
    "100%": {
      transform: "scale(2.4)",
      opacity: 0,
    },
  },
}));

export default function ProfileBadge() {
  const { profileData } = useAppStore();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [isLogoutDialogOpen, setIsLogoutDialogOpen] = React.useState(false);
  const [profileOpen, setProfileOpen] = useState(false);
  const navigate = useNavigate();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  // dialog
  const handleOpenLogoutDialog = () => {
    setIsLogoutDialogOpen(true);
  };

  const handleCloseLogoutDialog = () => {
    setIsLogoutDialogOpen(false);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const userName = profileData?.name.charAt(0);

  // Logout
  const handleLogout = async () => {
    // sessionStorage.removeItem("accesstoken");
    // sessionStorage.removeItem("mathcustomerstore");
    // navigate("/");
    // toast.success("Logged out successfully.");
    // setIsLogoutDialogOpen(false);

    try {
      const response = await axios.post(api_url + "auth/logout", {});
      const result = response.data;
      console.log("API Response:", result);
      if (result.status === "success") {
        sessionStorage.clear();
        sessionStorage.removeItem("sessionId");
        navigate("/");
        toast.success("Logged out successfully.");
        setIsLogoutDialogOpen(false);
      }
    } catch (error) {
      console.error("Error:", error.message);
    }
  };

  return (
    <>
      <Stack
        direction="row"
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            cursor: "pointer",
            // background: { xs: "none", md: "#ffe0b2" },

            // borderRadius: { xs: 0, md: 3 },
            // p: { xs: 0, md: 0.5 },
          }}
          onClick={handleClick}
        >
          <StyledBadge
            overlap="circular"
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            variant="dot"
          >
            <Avatar sx={{ bgcolor: deepOrange[500] }}>
              {userName.toUpperCase()}
            </Avatar>
          </StyledBadge>

          <Box
            ml={1}
            sx={{
              display: { xs: "none", lg: "flex" },
              alignItems: "center",
            }}
          >
            <Typography
              variant="subtitle1"
              sx={{
                color: "#212762",
                fontWeight: "bold",
                display: "flex",
                alignItems: "center",
              }}
            >
              Hello,{" "}
              <p style={{ fontFamily: "cursive", marginLeft: "2px" }}>
                {profileData?.name.split(" ")[0]?.[0].toUpperCase() +
                  profileData?.name.split(" ")[0]?.slice(1).toLowerCase()}
              </p>{" "}
              👋
            </Typography>
            <KeyboardArrowDownIcon sx={{ color: "#212762" }} />
          </Box>
        </Box>

        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          PaperProps={{
            style: { width: "200px", marginTop: "10px" }, // Set the desired width
          }}
        >
          <List>
            <ListItem
              button
              onClick={() => {
                handleClose();
                navigate("/profile");
              }}
            >
              <ListItemIcon>
                <StyledBadge overlap="circular">
                  <Avatar sx={{ bgcolor: deepOrange[500] }}>
                    {userName.toUpperCase()}
                  </Avatar>
                </StyledBadge>
              </ListItemIcon>
              <ListItemText primary="My Profile" />
            </ListItem>
            <Divider />
            <ListItem button onClick={handleOpenLogoutDialog}>
              <ListItemIcon>
                <LogoutIcon sx={{ color: "red" }} />
              </ListItemIcon>
              <ListItemText primary="Logout" sx={{ color: "red" }} />
            </ListItem>
          </List>
        </Popover>
      </Stack>

      <Dialog
        open={isLogoutDialogOpen}
        onClose={handleCloseLogoutDialog}
        aria-labelledby="logout-dialog-title"
        aria-describedby="logout-dialog-description"
      >
        <DialogTitle
          id="logout-dialog-title"
          sx={{ fontWeight: "bold", display: "flex", alignItems: "center" }}
        >
          <ReportIcon sx={{ color: "red", mr: 1, ml: -0.5 }} />
          Confirm Logout
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="logout-dialog-description">
            Are you sure, you want to log out?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCloseLogoutDialog}
            color="primary"
            sx={{ textTransform: "capitalize" }}
          >
            Cancel
          </Button>
          <Button
            onClick={handleLogout}
            variant="contained"
            color="error"
            sx={{ textTransform: "capitalize" }}
          >
            Logout
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
